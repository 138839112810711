html, body {
    overflow: hidden;
}
#root {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Calc-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
}

/* Start Display style */
.Calc-display{
    width: 100vw;
    height: 20vh;
    background:white;
    padding: 0 30px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color:#004d66;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    text-align: right;
}
.Calc-expression{
    width: 100%;
    height: 70%;
    font-size: 3rem;
    align-content: center;
}
.Calc-result{
    width: 100%;
    height: 30%;
    font-size: 1.5rem;
}

.Calc-keys{
    width: 100vw;
    height: 80vh;
    display: flex;
}
/* Start Keypad style */
.Calc-keypad {
    width: 75%;
    height: 80vh;
    display: flex;
    flex-wrap: wrap;
}
.Calc-keypad button{
    background: #0099cc;
}
.Calc-keypad .Calc-key{
    flex: 1 0 32%;
}
/* Start Operators style */
.Calc-operators {
    width: 25%;
    height: 80vh;
    display: flex;
    flex-direction: column;
}
.Calc-operators button {
    background: #ff3399;
}
.Calc-operators .Calc-key {
    width: 100%;
    flex: 1 0 auto;
}

/* Start Key style */
.Calc-key{
    border: none;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    padding: 0;
}
.Calc-key:active{
    background: lightslategray;
}
.Calc-key:focus{
    outline: none;
}

@media only screen and (min-width: 992px) {
    html, body {
        background-image: linear-gradient(to bottom right, #33ccff, #ff99cc);
        height: 100vh;
    }
    .Calc-app {
        width: 400px;
        height: 500px;
    }
    .Calc-display{
        width: 100%;
        height: 25%;
        font-size: 75px;
    }    
    .Calc-keys{
        width: 100%;
        height: 75%;
        display: flex;
        align-items: stretch;
    }
    .Calc-keypad {
        width: 75%;
        height: 100%;
    }
    .Calc-keypad .Calc-key {
        width: 32%;
        /* height: 25%; */
    }
    .Calc-operators {
        width: 25%;
        height: 100%;
    }
    .Calc-operators .Calc-key{
        width: 100%;
        flex: 1 0;
    }
}